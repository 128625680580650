<template>
  <div class="import-box" ref="importBox">
    <div class="upload-box" v-if="selectPanel === 1">
      <div class="upload-wrapper">
        <i v-if="status==='upload'" class="el-icon-upload" @click.stop="uploadFile"></i>
        <i v-else-if="status==='loading'" class="el-icon-loading" @click.stop="uploadFile"></i>
        <i v-else-if="status==='success'"  class="el-icon-success" @click.stop="uploadFile"></i>
        <i v-else-if="status==='error'"  class="el-icon-warning" @click.stop="uploadFile"></i>
        <p>Drag files here, <em>click upload</em>, <em>copy clipboard</em></p>
        <p>Only txt file can upload,and shouldn't exceed 1M</p>
      </div>
    </div>
    <div class="select-box" v-else-if="selectPanel === 2">
      <el-table
        class="data-table"
        ref="multipleTable"
        :row-class-name="tableRowClassName"
        :height="tableHeight"
        :data="tableData"
        :row-style="{height: '30px'}"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="Number" width="120">
          <template slot-scope="scope">
            {{ scope.row.number }}
          </template>
        </el-table-column>
        <el-table-column label="Message">
          <template slot-scope="scope">
            <div class="over-text-wrapper">
              <el-tooltip :content="scope.row.message" placement="top-start" effect="light">
                <span class="over-text">
                  {{ scope.row.message }}
                </span>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Edit" width="50">
          <template slot-scope="scope">
            <div style="text-align: center;">
              <i type="danger" class="el-icon-delete" size="mini" @click.stop="deleteItem(scope.$index)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="btns-box">
        <el-button @click.stop="cancel" size="mini" type="info" icon="el-icon-arrow-left">Cancel</el-button>
        <el-button @click.stop="confirm" size="mini" type="primary" icon="el-icon-check">Confirm</el-button>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import { getAccount } from '@/api/account'
export default {
  name: 'translate',
  data() {
    return {
      status: 'upload',
      okLoading: false,
      tableData: [],
      tableHeight: 0,
      selectPanel: 1,
      dialog: false
    }
  },
  created() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.importBox.clientHeight
    })
  },
  computed: {
    ...mapGetters([
      'fromPhone',
      'phones',
      'limitRepeat',
      'userId',
      'send'
    ])
  },
  methods: {
    tableRowClassName({row}) {
      return row.warning ? 'warning-row' : ''
    },
    deleteItem(index) {
      this.tableData.splice(index, 1)
    },
    async confirm() {
      if (!this.tableData.length) {
        this.status = 'upload'
        this.selectPanel = 1
        return
      }
      if (!this.fromPhone) {
        this.$message({
          type: 'warning',
          message: 'Please select the sending number on the right.'
        })
        return
      }
      let warningCount = 0
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i]
        if (row.warning) {
          warningCount++
        }
      }
      
      // check balance enough
      const response = await getAccount(this.userId)
      const { integral } = response.data
      const total = this.send * this.tableData.length
      if (parseFloat(integral) < total) {
        this.$message({
          type: 'warning',
          message: 'Your integral not enough, please recharge before operation!'
        })
        return
      }

      if (warningCount > 0) {
        this.$alert(`There are ${warningCount} limit messages. Do you confirm to upload?`, 'Upload Warning', {
          confirmButtonText: 'Confirm',
          callback: (action) => {
            if (action === 'confirm') {
              this.$message({
                type: 'success',
                message: 'Upload successful, restriction message ignored'
              })
              this.selectPanel = 1
              this.status = 'upload'
              const messages = this._wrapperMessages()
              this.$store.dispatch('sms/SetUploadMessages', messages)
              this.tableData = []
            }
          }
        })
      } else {
        this.$message({
          type: 'success',
          message: 'Upload successful.'
        })
        const messages = this._wrapperMessages()
        this.$store.dispatch('sms/SetUploadMessages', messages)
        this.tableData = []
        this.selectPanel = 1
        this.status = 'upload'
      }
    },
    _wrapperMessages() {
      const messages = []
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i]
        if (!row.warning) {
          messages.push({
            phone: row.number,
            text: row.message,
            number: this.fromPhone.number,
            fullNumber: this.fromPhone.fullNumber
          })
        }
      }
      return messages
    },
    cancel() {
      this.numbers = []
      this.status = 'upload'
      this.selectPanel = 1
    },
    uploadFile() {
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'text/plain'
      input.multiple = false
      input.addEventListener('change', (event) => {
        const file = event.currentTarget.files[0]
        this.status = 'loading'
        var reader = new FileReader()
        try {
          reader.readAsText(file, 'UTF-8')
          reader.onload = (e) => {
            try {
              const messageHistroy = localStorage.getItem('msg_history')
              let history = []
              if (messageHistroy) {
                history = JSON.parse(messageHistroy)
                history = history.slice(0, Math.max(0, Math.min(history.length, this.limitRepeat)))
              }
              const result = e.currentTarget.result
              let newResult = result.replaceAll('\n', '\r\n')
              const lines = newResult.split('\r\n')
              const tableRows = []
              for (let i = 0; i < lines.length; i++) {
                const line = lines[i]
                const firstIndex = line.indexOf(' ')
                const number = line.substring(0, firstIndex)
                const message = line.substring(firstIndex + 1)
                if (number && message) {
                  const isRepeat = history.includes(message)
                  const numberValid = number.length !== 11
                  const warning = isRepeat || numberValid
                  tableRows.push({
                    number: number,
                    message: message,
                    warning: warning
                  })
                  if (!warning) {
                    const newHistory = [message, ...history]
                    history = newHistory.slice(0, Math.max(0, Math.min(newHistory.length, this.limitRepeat)))
                  }
                }
              }
              this.status = 'success'
              if (tableRows.length === 0) {
                this.status = 'upload'
                return
              }
              this.selectPanel = 2
              this.tableData = tableRows
            } catch(e) {
              console.error(e)
              this.status = 'error'
            }
          }
        } catch (error) {
          this.status = 'error'
          this.$message.error('Read content failed.')
        }
      })
      input.click()
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
.import-box {
  margin: 4px;
  height: 100%;
  .el-carousel__item h3 {
    font-size: 14px;
    opacity: 0.75;
    margin: 0;
  }
  .el-carousel, .el-carousel__container {
    height: 100%;
  }
  .el-upload-dragger {
    border: none;
  }
  .upload-box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .upload-wrapper {
      background-color: #fff;
      width: 360px;
      height: 180px;
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      p {
        color: #606266;
        font-size: 14px;
        text-align: center;
        margin: 4px 0;
        em {
          color: #409eff;
          font-style: normal;
          font-size: 14px;
          text-align: center;
        }
      }
      .el-icon-upload, .el-icon-warning, .el-icon-success, .el-icon-loading {
        font-size: 67px;
        margin: 16px 0;
        line-height: 50px;
      }
      .el-icon-upload, .el-icon-loading {
        color: #C0C4CC;
      }
      .el-icon-warning {
        color: rgb(236, 52, 52);
      }
      .el-icon-success {
        color: #00796b;
      }
    }
  }
  .select-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    .btns-box {
      width: 100%;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .over-text-wrapper {
      overflow: hidden;
      .over-text {
        width: 160px;
        display: inline-block;
        word-break:keep-all;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
      }
    }
    .el-table .warning-row {
      cursor: pointer;
      background: #fff4d655;
      color: #000;
    }
    .el-icon-delete {
      color: rgb(255, 82, 82);
    }
  }
}
</style>
      